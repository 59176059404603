import { IDatePickerStrings } from '@fluentui/react';
import L from "leaflet";
import { IAssignmentDTO, ICreateOrUpdateSupplierDTO, ILayer } from '../models';
import { orderBy } from 'lodash';

export default class Utilities {
    public static DayPickerStrings: IDatePickerStrings = {
        months: ['Januar', 'Februar', 'Marts', 'April', 'Maj', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'December'],

        shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],

        days: ['Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag'],

        shortDays: ['S', 'M', 'TI', 'O', 'TO', 'F', 'L'],

        goToToday: 'Gå til dags dato',
        prevMonthAriaLabel: 'Gå til forrige måned',
        nextMonthAriaLabel: 'Gå til næste måned',
        prevYearAriaLabel: 'Gå til forrige år',
        nextYearAriaLabel: 'Gå til næste år',
        closeButtonAriaLabel: 'Luk datovælger'
    };

    public static FormatDate(date?: Date): string {
        return !date ? '' : Utilities.pad(date.getDate(), 2) + '-' + Utilities.pad((date.getMonth() + 1), 2) + '-' + date.getFullYear();
    };

    // From UTC to local time and supporting Safari on iOS
    public static ConvertFromUTCToLocalTime(dateAsString: string)
    {
        let parts : string[] = dateAsString.split(/[^0-9]/);
        return new Date(Date.UTC(
            parseInt(parts[0]),
            parseInt(parts[1]) - 1,
            parseInt(parts[2]),
            parseInt(parts[3]),
            parseInt(parts[4]),
            parseInt(parts[5])));
    }

    private static pad(num : number, size : number) {
        let s = num + "";
        while (s.length < size) s = "0" + s;
        return s;
    }
    

    public static CopyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
        const key = columnKey as keyof T;

        if(key == "created")
        {
            return items.slice(0).sort((a: T, b: T) => 
            {
                let field = key as string;
                if((a as IAssignmentDTO).status == Status.Approved)
                {
                    field = "approvedDate";
                }

                return ((isSortedDescending ? new Date(a[field] as any).getTime() < new Date(b[field] as any).getTime() : new Date(a[field] as any).getTime() > new Date(b[field] as any).getTime()) ? 1 : -1)
            });
        } else if(key == "estimatedAmount")
        {
            return orderBy(items, [a => a[key] ?? 0], [isSortedDescending ? "desc" : "asc"]);
        } else
        {            
            return orderBy(items, [a => (a[key] + "").toLocaleLowerCase()], [isSortedDescending ? "desc" : "asc"]);
        }
    }

    public static CheckNotNullOrEmpty(text: string | undefined | null)
    {
        if(text == undefined || text == null)
        {
            return false;
        }

        return text != "" && text + "" != "";
    }

    public static NewGuid() 
    {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
          var r = Math.random() * 16 | 0,
            v = c == 'x' ? r : (r & 0x3 | 0x8);
          return v.toString(16);
        });
    }
}

//Allow custom projection
(window.L as any).Proj = require('proj4leaflet');

export class MapUtilities
{
    public static CreateTextIcon(html: string)
    {
        return L.divIcon({ className: 'text-labels', html: html });
    }
    
    public static none = L.icon({ iconUrl: "images/marker-icon-transparent.png" });
    //public static fixedTaskPositionIcon = L.icon({ iconUrl: "images/marker-icon-gold.png" });
    //public static stackIcon = L.icon({ iconUrl: "images/HD-Icon-Stack.png", tooltipAnchor: [0, 0] });
    //public static containerspotIcon = L.icon({ iconUrl: "images/HD-Icon-Containerspot.png" });

    public static CreateFixedTaskPositionIcon(zoomLevel) : L.Icon
    {        
      let defaultWidth = 25;
      let defaultHeight = 41;

      let sizes = {
        13: [defaultWidth, defaultHeight], //nearest zoom level, e.g. biggest icon size
        12: [defaultWidth * 0.95, defaultHeight * 0.95],
        11: [defaultWidth * 0.90, defaultHeight * 0.90],
        10: [defaultWidth * 0.85, defaultHeight * 0.85],
        9:  [defaultWidth * 0.80, defaultHeight * 0.80],
        8:  [defaultWidth * 0.50, defaultHeight * 0.50],
        7:  [defaultWidth * 0.45, defaultHeight * 0.45],
        6:  [defaultWidth * 0.40, defaultHeight * 0.40],
        5:  [defaultWidth * 0.35, defaultHeight * 0.35],
        4:  [defaultWidth * 0.30, defaultHeight * 0.30],
        3:  [defaultWidth * 0.25, defaultHeight * 0.25]
      };

      return L.icon({ iconUrl: "images/marker-icon-gold.png", iconSize: sizes[zoomLevel] });
    }

    public static CreateStackIcon(zoomLevel, direction: string = "top", tooltipAnchor = [0, -15] as L.PointExpression) : L.Icon
    {        
      let defaultWidth = 34;
      let defaultHeight = 30;

      let sizes = {
        13: [defaultWidth, defaultHeight], //nearest zoom level, e.g. biggest icon size
        12: [defaultWidth * 0.95, defaultHeight * 0.95],
        11: [defaultWidth * 0.90, defaultHeight * 0.90],
        10: [defaultWidth * 0.85, defaultHeight * 0.85],
        9:  [defaultWidth * 0.80, defaultHeight * 0.80],
        8:  [defaultWidth * 0.50, defaultHeight * 0.50],
        7:  [defaultWidth * 0.45, defaultHeight * 0.45],
        6:  [defaultWidth * 0.40, defaultHeight * 0.40],
        5:  [defaultWidth * 0.35, defaultHeight * 0.35],
        4:  [defaultWidth * 0.30, defaultHeight * 0.30],
        3:  [defaultWidth * 0.25, defaultHeight * 0.25]
      };
      
      switch(direction)
      {
        case "left":
            tooltipAnchor = [-15, 0];
            break;
        case "right":
            tooltipAnchor = [15, 0];
            break;
        case "top":
            tooltipAnchor = [0, -15];
            break;
        case "bottom":
            tooltipAnchor = [0, 15];
            break;
      }

      return L.icon({ iconUrl: "images/HD-Icon-Stack.png", iconSize: sizes[zoomLevel], tooltipAnchor: tooltipAnchor });
    }

    public static CreateContainerspotIcon(zoomLevel) : L.Icon
    {        
      let defaultWidth = 34;
      let defaultHeight = 30;

      let sizes = {
        13: [defaultWidth, defaultHeight], //nearest zoom level, e.g. biggest icon size
        12: [defaultWidth * 0.95, defaultHeight * 0.95],
        11: [defaultWidth * 0.90, defaultHeight * 0.90],
        10: [defaultWidth * 0.85, defaultHeight * 0.85],
        9:  [defaultWidth * 0.80, defaultHeight * 0.80],
        8:  [defaultWidth * 0.50, defaultHeight * 0.50],
        7:  [defaultWidth * 0.45, defaultHeight * 0.45],
        6:  [defaultWidth * 0.40, defaultHeight * 0.40],
        5:  [defaultWidth * 0.35, defaultHeight * 0.35],
        4:  [defaultWidth * 0.30, defaultHeight * 0.30],
        3:  [defaultWidth * 0.25, defaultHeight * 0.25]
      };

      return L.icon({ iconUrl: "images/HD-Icon-Containerspot.png", iconSize: sizes[zoomLevel] });
    }

    public static CRS = new (L as any).Proj.CRS('EPSG:25832',
	      '+proj=utm +zone=32 +ellps=GRS80 +units=m +no_defs', {
        resolutions: [1638.4,819.2,409.6,204.8,102.4,51.2,25.6,12.8,6.4,3.2,1.6,0.8,0.4,0.2],
        origin: [120000,6500000],
        bounds: L.bounds([120000, 5661139.2],[1378291.2, 6500000])
    });

    public static ConfigureLeafLet()
    {
        (L as any).drawLocal.draw.handlers.polyline.tooltip.start = "Klik på kortet for at starte linjen";
        (L as any).drawLocal.draw.handlers.polyline.tooltip.cont = "Klik på kortet for at fortsætte linjen";
        (L as any).drawLocal.draw.handlers.polyline.tooltip.end = "Klik på det sidste punkt for at afslutte linjen";
        (L as any).drawLocal.draw.handlers.marker.tooltip.start = "Klik på kortet for at placere det valgte element";
        (L as any).drawLocal.draw.handlers.polygon.tooltip.start = "Klik på kortet for at starte arbejdsområdet";
        (L as any).drawLocal.draw.handlers.polygon.tooltip.cont = "Klik på kortet for at tegne næste punkt i arbejdsområdet";
        (L as any).drawLocal.draw.handlers.polygon.tooltip.end = "Klik på det første punkt for at afslutte arbejdsområdet";
        L.Icon.Default.imagePath='images/';
    }
}

export enum Status
{
    Open = "Kladde",
    WaitingForApproval = "Afventer Dalgas godkendelse",
    Rejected = "Afvist",
    RequiresAdditionalInfo = "Kræver yderligere info",
    Approved = "Godkendt"
}