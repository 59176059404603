import React from 'react';
import { PrimaryButton, DefaultButton, Panel, PanelType, Label, DatePicker, DayOfWeek, Dropdown, IDropdownOption, Stack, TextField, MessageBar, MessageBarType, ComboBox, IComboBox, IComboBoxOption, Toggle, IconButton, Link, Icon, SelectableOptionMenuItemType, TooltipHost, TagPicker, ITag } from '@fluentui/react';
import { IAssignmentDTO, ICertificationDTO, ICreateOrUpdateSupplierDTO, IMapData, ISBPPersonDTO, IStackMarker, WorkingAreaType } from '../../../models';
import styles from './AssignmentPanel.module.scss';
import { Services, IUserService } from '../../../services';
import App from '../../../App';
import { VisibilityControl } from '../../VisibilityControl/VisibilityControl';
import Utilities, { Status } from '../../../helpers/Utilities';
import LeafletMap from '../Maps/LeafletMap';
import DocumentationDialog from '../Documentation/DocumentationDialog';
import DelegatePanel from '../DelegatePanel/DelegatePanel';
import { IAssignmentFileDTO } from '../../../models/DTO/IAssignmentFileDTO';
import { TooltipComponent } from '../../../helpers/TooltipComponent';
import { AssignmentServiceProvider, IAssignmentService } from '../../../services/assignmentService';
import { UserServiceProvider } from '../../../services/userService';

interface IAssignmentPanelProps
{
    selectedAssignment? : IAssignmentDTO;
    onDismiss(): void;
    onChanged(assignment: IAssignmentDTO, showAssignmentPanel: boolean): void;
    allowSelectionOfSupplier: boolean;
}

interface IAssignmentPanelState
{
    step: CreationSteps;
    mode: FormMode;
    currentItem : IAssignmentDTO;
    stackSumFromMap: number;
    stackTreeSpecices: string;
    canSubmit: boolean;
    extendedCreationRequired: boolean;
    SBP_Persons: ISBPPersonDTO[];
    suppliers: ICreateOrUpdateSupplierDTO[];
    certifications?: ICertificationDTO[];
    isSaving: boolean;
    showDelegatePanel: boolean;
    showValidationBox: boolean;
    validationMessage: string;
    showDocumentationOutdatedWarning: boolean;
}

enum CreationSteps
{
    Assignment,
    Map,
    Documentation
}

enum FormMode
{
    New,
    Edit,
    ReadOnly
}

export default class AssignmentPanel extends React.Component<IAssignmentPanelProps, IAssignmentPanelState> {    
    private assignmentService : IAssignmentService;
    private userService : IUserService;
    private fileInput_OwnMap;
    private fileInput_Declaration;

    constructor(props: IAssignmentPanelProps) {
        super(props);
        
        this.assignmentService = Services.AssignmentService.Initialize(new AssignmentServiceProvider());
        this.userService = Services.UserService.Initialize(new UserServiceProvider());

        // Set form mode for supplier
        let mode = props.allowSelectionOfSupplier ? FormMode.New : props.selectedAssignment ? (props.selectedAssignment.status == Status.WaitingForApproval || props.selectedAssignment.status == Status.Approved || props.selectedAssignment.status == Status.Rejected) ? FormMode.ReadOnly : FormMode.Edit : FormMode.New;

        if(props.selectedAssignment && App.IsUserInRole("DL"))
        {
            mode = props.selectedAssignment.status == Status.Approved ? FormMode.ReadOnly : FormMode.Edit;
        }

        this.state = {
            step: CreationSteps.Assignment,
            mode: mode,
            canSubmit: false,
            extendedCreationRequired: false,
            currentItem: props.selectedAssignment ? props.selectedAssignment : { created: new Date(), status: Status.Open, hdaoNo: undefined, files: [] },
            stackSumFromMap: 0,
            stackTreeSpecices: "",
            SBP_Persons: [] as ISBPPersonDTO[],
            suppliers: [] as ICreateOrUpdateSupplierDTO[],
            isSaving: false,
            showDelegatePanel: false,
            showValidationBox: false,
            validationMessage: "",
            showDocumentationOutdatedWarning: false
        }

        this.load();
    }

    public componentDidUpdate(prevProps: IAssignmentPanelProps, prevState: IAssignmentPanelState) : void
    {
        if(prevState.currentItem.mapTimestamp != this.state.currentItem.mapTimestamp || prevState.currentItem.documentationTimestamp != this.state.currentItem.documentationTimestamp)
        {
            this.hasMapBeenChangedAfterDocumentationProcess();
        }
    }

    private filterSuggestedTags(filterText: string, selectedItems?: ITag[]): ITag[]
    {
        const res = filterText
          ? this.state.suppliers.filter(tag => tag.name.toLowerCase().indexOf(filterText.toLowerCase()) > -1)
          : this.state.suppliers;

          return res.map((v, i, a) => { return { key: v.id, name: v.name } });
      };

    public render(): React.ReactElement<IAssignmentPanelProps> {
        return (
            <Panel
              headerText={this.props.selectedAssignment ? "Rediger opgave" : "Opret ny opgave"}
              isOpen={true}              
              className={styles.createPanel}                 
              isLightDismiss={false}
              isBlocking={true}            
              onRenderFooterContent={() => {
                  return (<div>
                      { (this.state.mode == FormMode.New || this.state.mode == FormMode.Edit) &&
                        <React.Fragment>
                            { App.IsUserInRole("DL") && this.state.showValidationBox &&
                                <MessageBar className={styles.validationBox} messageBarType={MessageBarType.severeWarning} isMultiline={true}>
                                    <div dangerouslySetInnerHTML={{__html: this.state.validationMessage}} />
                                </MessageBar>
                            }
                            <VisibilityControl name="disclaimer" visible={!App.IsUserInRole("DL") && this.state.currentItem.status == Status.Open}>
                                <Label>Ved overførsel af opgaven til Dalgas bekræfter jeg at Dalgas SBP procedure er overholdt og at Dalgas må udføre efterfølgende kontrol på arealet.</Label>
                            </VisibilityControl>     
                            <VisibilityControl name ="Gem kladde" visible={App.IsUserInRole("Supplier") && (this.state.currentItem.status == Status.Open || this.state.currentItem.status == Status.RequiresAdditionalInfo) || (App.IsUserInRole("DL") && this.state.currentItem.status != Status.Approved && this.state.currentItem.status != Status.Rejected && this.state.currentItem.status != Status.RequiresAdditionalInfo)}>                       
                                <PrimaryButton  
                                    className={styles.button}
                                    disabled={this.state.isSaving}
                                    onClick={() => {this.onAddOrUpdateAssignment(this.state.currentItem)}}
                                    >
                                    Gem kladde
                                </PrimaryButton>
                            </VisibilityControl>                                                      
                            <VisibilityControl name ="Overfør til Dalgas godkendelse" visible={App.IsUserInRole("Supplier") && (this.state.currentItem.status == Status.Open || this.state.currentItem.status == Status.RequiresAdditionalInfo) && !this.props.allowSelectionOfSupplier}>
                                <PrimaryButton 
                                    className={styles.button}
                                    disabled={this.state.isSaving}
                                    onClick={async () => { 
                                        let currentItem = {...this.state.currentItem};
                                        currentItem.status = Status.WaitingForApproval;

                                        this.onAddOrUpdateAssignment(currentItem); 
                                    } }
                                    >
                                    Overfør til Dalgas godkendelse
                                </PrimaryButton>  
                            </VisibilityControl>   
                            <VisibilityControl name ="Godkend" visible={App.IsUserInRole("DL") && (this.state.currentItem.status == Status.WaitingForApproval || this.props.allowSelectionOfSupplier)}>
                                <PrimaryButton 
                                    className={styles.approveButton}                                     
                                    onClick={async () => { 
                                        if(this.validateForm_CanApprove())
                                        {
                                            let currentItem = {...this.state.currentItem};
                                            currentItem.status = "Godkendt";                                        
    
                                            this.onAddOrUpdateAssignment(currentItem); 
                                        }
                                    } }
                                    disabled={this.state.isSaving || this.state.showDocumentationOutdatedWarning}
                                    >
                                    Godkend                                    
                                </PrimaryButton>  
                            </VisibilityControl>
                            <VisibilityControl name ="Afvis" visible={App.IsUserInRole("DL") && (this.state.currentItem.status == Status.WaitingForApproval)}>
                                <React.Fragment>
                                    <PrimaryButton 
                                        className={styles.rejectButton} 
                                        disabled={this.state.isSaving}
                                        onClick={async () => { 
                                            let currentItem = {...this.state.currentItem};
                                            currentItem.status = "Afvist";

                                            this.onAddOrUpdateAssignment(currentItem); 
                                        } }
                                        >
                                        Afvis
                                    </PrimaryButton> 
                                    <PrimaryButton 
                                        className={styles.requiresAdditionalInfo} 
                                        disabled={this.state.isSaving}
                                        onClick={async () => { 
                                            let currentItem = {...this.state.currentItem};
                                            currentItem.status = "Kræver yderligere info";

                                            this.onAddOrUpdateAssignment(currentItem); } 
                                        }
                                        >
                                        Retur til leverandør
                                    </PrimaryButton>
                                    <PrimaryButton 
                                        className={styles.delegateInternally} 
                                        disabled={this.state.isSaving}
                                        onClick={async () => { this.setState({ showDelegatePanel: true });}}>
                                        Deleger internt
                                    </PrimaryButton>
                                </React.Fragment>
                            </VisibilityControl>                       
                        </React.Fragment>
                    }                   
                    <VisibilityControl name ="Slet opgave" visible={(this.state.mode == FormMode.Edit || this.state.mode == FormMode.ReadOnly) && (this.state.currentItem.status == Status.Open || this.state.currentItem.status == Status.Rejected)}>
                        <PrimaryButton 
                            className={styles.deleteButton} 
                            onClick={async () => { this.deleteAssignment(); } }
                            >
                            Slet opgave
                        </PrimaryButton> 
                    </VisibilityControl>                    
                    <VisibilityControl name ="Send retur til DL" visible={App.IsUserInRole("Admin") && (this.state.currentItem.status == Status.Approved)}>                                
                        <PrimaryButton 
                            className={styles.rejectButton} 
                            disabled={this.state.isSaving}
                            onClick={async () => {   
                                if(window.confirm("Er du sikker på du vil sende opgaven tilbage til driftslederen?"))
                                {
                                    this.onAddOrUpdateAssignment(this.state.currentItem); 
                                }                                
                            } }
                            >
                            Send retur til DL (!)
                        </PrimaryButton> 
                    </VisibilityControl>
                    <DefaultButton onClick={() => this.props.onDismiss()}>Annuller</DefaultButton>                    
                    </div>);                
                }
              }
              onDismiss={(ev?: React.SyntheticEvent<HTMLElement> | KeyboardEvent) => { if((ev as any).keyCode !== 27) this.props.onDismiss() }}              
              closeButtonAriaLabel="Luk opgavepanel"
              type={PanelType.medium}>
                <Stack>
                    { this.state.mode != FormMode.New &&
                        <React.Fragment>
                            <MessageBar messageBarType={this.state.currentItem.status == Status.Rejected ? MessageBarType.error : MessageBarType.info} isMultiline={false}>
                                <div>Oprettet af: {this.state.currentItem.createdBy}</div>
                                <span>Status: {this.state.currentItem.status}</span>
                                {
                                this.state.currentItem.status == Status.Approved ? <span>&nbsp;{this.state.currentItem.approvedDate?.toLocaleDateString()} af {this.state.currentItem.modifiedBy}</span> : <></>
                                }
                            </MessageBar>                            
                        </React.Fragment>
                    }
                    { this.props.allowSelectionOfSupplier && 
                    <>
                        <Label required>Leverandør</Label>
                        <TagPicker
                            itemLimit={1}                            
                                selectionAriaLabel="Vælg leverandør"
                                onResolveSuggestions={this.filterSuggestedTags.bind(this)}
                                getTextFromItem={(item: ITag) => item.name}
                                pickerSuggestionsProps={{
                                    suggestionsHeaderText: 'Leverandører',
                                    noResultsFoundText: 'Intet match fundet.',
                                }}
                                inputProps={{
                                    placeholder: "Søg efter leverandør"
                                }}
                                disabled={this.state.mode == FormMode.ReadOnly}
                                onChange={this.onSupplierChange.bind(this) }
                            /> 
                        </>                                               
                    }
                    <TextField 
                        required={App.IsUserInRole("DL")}
                        onRenderLabel={() => { return <TooltipComponent name="Opgavenavn" description="Navn der identificerer opgaven. Kan indeholde et leverandørnummer fra jeres eget system e.l. &#10;Det er kun tilladt at bruge tal og bogstaver." /> }}
                        disabled={this.state.mode == FormMode.ReadOnly}
                        placeholder="Angiv navnet på opgaven" 
                        value={this.state.currentItem.orderName} 
                        onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => { this.updateProperty({ orderName: this.trimOrderName(newValue + "")}) } }                        
                    />
                    { (App.IsUserInRole("DL") || App.IsUserInRole("Admin") || App.IsUserInRole("Finance")) && 
                        <React.Fragment>
                            <Stack horizontal horizontalAlign="space-between" verticalAlign="end">
                                <Stack.Item grow={8}>
                                    <TextField 
                                        value={this.state.currentItem.hdaoNo != undefined ? this.state.currentItem.hdaoNo.toLocaleString() : undefined} 
                                        label="Dalgas AO nr." 
                                        required={true}
                                        disabled={true}
                                        multiline={false}
                                        onChange={((event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => { this.updateProperty({ hdaoNo: parseInt(newValue + "") }) } )}             
                                    />
                                </Stack.Item>
                                <Stack.Item grow={1}>
                                    <IconButton disabled={this.state.currentItem.hdaoNo != undefined} iconProps={{iconName: "PageLock"}} title="Udtag Dalgas AO nr." ariaLabel="Udtag Dalgas AO nr." onClick={this.takeNextPINFONo.bind(this)} /> 
                                </Stack.Item>                                
                            </Stack>                                   
                            <TextField 
                                value={this.state.currentItem.projectNo} 
                                label="Projektnummer" 
                                required={true}
                                disabled={this.state.mode == FormMode.ReadOnly}
                                multiline={false}
                                onChange={((event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => { if(newValue && newValue?.length <= 20) this.updateProperty({ projectNo: newValue }) } )}             
                            />                           
                        </React.Fragment>
                    }  
                    { (this.state.certifications && this.state.certifications.length > 1) &&
                        <Dropdown                        
                            placeholder="Vælg fra listen"                            
                            disabled={this.state.mode == FormMode.ReadOnly}
                            onRenderLabel={() => { return <TooltipComponent name="Certificeret" description="Hvis træet/flisen sælges til Dalgas som certificeret fra leverandørens side så vælges det.&#10;Ellers vælges ”Træet er forberedt til Dalgas SBP certificering”" /> }}                            
                            selectedKey={this.state.currentItem.certification == undefined ? "" : this.state.currentItem.certification.id}
                            options={this.state.certifications ? this.state.certifications.map((value : ICertificationDTO) => { return { key: value.id, text: value.name } }) : [] as IDropdownOption[]}
                            onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => { this.setState({ currentItem: { ...this.state.currentItem, certification: this.state.certifications?.filter(d => d.id ==  option?.key)[0] }}, () => { this.validateForm(); }) }}
                        />   
                    }   
                    
                    <Dropdown
                        required={App.IsUserInRole("DL")}
                        placeholder="Vælg fra listen"
                        disabled={this.state.mode == FormMode.ReadOnly}
                        onRenderLabel={() => { return <TooltipComponent name="Flistype" description="Opdelt efter de typer som findes i bæredygtighedsloven. Se Flisportalens hjælpeside for yderlige information." /> }}                        
                        selectedKey={this.state.currentItem.biomassCategory}
                        options={[
                            { key: 'Skov: rundtræ/stammetræ', text: 'Skov: rundtræ/stammetræ' },
                            { key: 'Skov: restprodukter fra skovbrug inkl. alle tyndinger', text: 'Skov: restprodukter fra skovbrug inkl. alle tyndinger' },
                            { key: 'Skov: afdrift kun til energi (afdrift hvor alt materiale går til flis)', text: 'Skov: afdrift kun til energi (afdrift hvor alt materiale går til flis)' },
                            { key: 'Ikke skov: læhegn, småbevoksninger under 0,5ha og naturpleje', text: 'Ikkeskov: læhegn, småbevoksninger under 0,5ha og naturpleje' },
                            { key: 'Træaffald inkl. fra omkring infrastruktur, byggemodning, have-, park-,  vej- og bytræer', text: 'Træaffald inkl. fra omkring infrastruktur, byggemodning, have-, park-,  vej- og bytræer' },
                            { key: 'Landbrug: Energipil og energipoppel', text: 'Landbrug: Energipil og energipoppel'},
                            { key: 'Resttræ fra landbrugsarealer inkl juletræer og frugtplantager', text: 'Resttræ fra landbrugsarealer inkl juletræer og frugtplantager'},
                            { key: 'Træindustri: restprodukter fra træindustri', text: 'Træindustri: restprodukter fra træindustri'}
                        ]}
                        onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => { this.updateProperty({ biomassCategory: option?.key + ""}); } }
                    />   
                    <TextField 
                        required={App.IsUserInRole("DL")}
                        multiline={true} 
                        disabled={this.state.mode == FormMode.ReadOnly}
                        rows={6} 
                        onRenderLabel={() => { return <TooltipComponent name="Opgavebeskrivelse" description="Ekstra information om opgaven. F.eks.: hvad består opgaven af, er der noget særligt med adgangsforholdene som f.eks. bomkoder, aftaler omkring genetablering af ryddede arealer, osv." /> }}
                        placeholder="Angiv ekstra info om opgaven/lokationen" 
                        value={this.state.currentItem.description} 
                        onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => { this.updateProperty({ description: newValue}) } }
                    />          
                    <TooltipComponent name="Fældedato" description="Angiv den dato hvor de sidste træer i opgaven blev fældet. Hvis opgaven omfatter træ der har været fældet på meget forskellige tidspunkter så beskriv det under ”Opgavebeskrivelse”." />
                    <DatePicker
                        firstDayOfWeek={DayOfWeek.Monday}
                        placeholder="Vælg fældedato"
                        disabled={this.state.mode == FormMode.ReadOnly}
                        isRequired={App.IsUserInRole("DL")}
                        value={this.state.currentItem.trappingDate ? this.state.currentItem.trappingDate : undefined}
                        formatDate={Utilities.FormatDate}
                        onSelectDate={(val) => this.updateProperty({ trappingDate: val != null ? val: undefined})}
                        strings={Utilities.DayPickerStrings}
                    />
                    <PrimaryButton className={styles.defaultButton} onClick={() => { this.setState({step: CreationSteps.Map}); }}>
                        <TooltipComponent name={(this.state.currentItem.status == Status.Approved || this.state.currentItem.status == Status.Rejected || this.state.currentItem.status == Status.WaitingForApproval) ? "Åbn kort" : "Tegn kort"} description="Tegn kort over hvilke arbejdsområder som opgaven omfatter. Hvis du benytter ”Upload eget kort” så behøver du ikke udføre dette trin." />
                    </PrimaryButton>
                    { this.state.step == CreationSteps.Map && 
                        <LeafletMap currentItem={this.state.currentItem} 
                            onDismiss={(currentItem: IAssignmentDTO) => { this.setState({step: CreationSteps.Assignment}); }} 
                            onSaveClicked={(mapData: IMapData, canEdit: boolean) => { 
                                let treeSpecices = this.getTreeSpecicesUsedInStacks(mapData);

                                let timestamp = canEdit ? new Date() : this.state.currentItem.mapTimestamp;

                                this.setState({ 
                                    stackSumFromMap: mapData.stackSum,
                                    stackTreeSpecices: treeSpecices,                                 
                                    currentItem: {...this.state.currentItem, mapData: JSON.stringify(mapData), estimatedAmount: mapData.stackSum == 0 ? undefined : mapData.stackSum, mapTimestamp: timestamp }, step: CreationSteps.Assignment
                                }); 
                            }} />
                    }
                                        
                    {(this.state.mode == FormMode.New || this.state.mode == FormMode.Edit) &&
                        <React.Fragment>
                            <PrimaryButton className={styles.defaultButton} onClick={() => { this.fileInput_OwnMap.click() }}>
                                <TooltipComponent name="Upload eget kort" description="Her kan du uploade bæredygtighedskort der er udarbejdet via en anden kortløsning end den der findes her. Kortet skal overholde de krav der gælder under Dalgas SBP certificering. Se Flisportalens hjælpeside for yderligere information." />
                            </PrimaryButton>
                            <input 
                                style={{display:"none"}}
                                type="file" 
                                multiple={true}
                                ref={fileInput => this.fileInput_OwnMap = fileInput} 
                                onChange={async (event) => { 
                                    if(event.target.files) 
                                    {
                                        await this.addFiles(event.target.files, "OwnMap");
                                    } 
                                }} 
                            />
                        </React.Fragment>
                    }                    
                    { this.state.mode != FormMode.ReadOnly && 
                        <PrimaryButton className={styles.defaultButton} onClick={async () => { if(this.state.currentItem.id == undefined) await this.preSaveAssignment(); this.setState({step: CreationSteps.Documentation}); }} disabled={this.state.currentItem.mapData == undefined || (JSON.parse(this.state.currentItem.mapData) as IMapData).fixedTaskPosition == undefined}>
                            <TooltipComponent name="Udarbejd dokumentation" description="Denne proces udarbejder et arbejdskort, et HNV kort samt et oversigtskort over opgaven. Hvis du ikke selv skal bruge disse kort behøver du ikke at udføre dette trin." />
                        </PrimaryButton>
                    }
                    { this.state.step == CreationSteps.Documentation && <DocumentationDialog currentItem={this.state.currentItem} onDismiss={() => { this.setState({step: CreationSteps.Assignment}); } } onSaved={(newFiles: IAssignmentFileDTO[]) => { 
                        let currentItem = this.state.currentItem;

                        let allFiles = currentItem.files?.filter(d => d.type != "Documentation");
                        allFiles?.push(...newFiles);

                        this.setState({currentItem: { ...this.state.currentItem, files: allFiles, documentationTimestamp: new Date() }, step: CreationSteps.Assignment });  
                        }} /> }
                    <Label>Dokumentation</Label>
                    <div>
                        {this.state.currentItem.files?.filter(d => d.type == "OwnMap" || d.type == "Documentation")?.map((v,i,a) =>{
                            return <div key={`documentationFile_${i}`}>
                                    { v.isNew ? 
                                        <Label className={styles.newLink}>{v.name}</Label> : 
                                        <div><Link onClick={async() => { this.downloadFile(v.name); }}>{v.name} ({v.type == "OwnMap" ? "eget kort" : "Dalgas dokumentation"})</Link> {v.type == "OwnMap" && <IconButton onClick={() => { if(window.confirm('Er du sikker på, at du ønsker at slette denne fil? Handlingen kan ikke fortrydes.') == true) this.deleteFile(v); }} iconProps={{ iconName: 'Delete' }} title="Slet" />}</div>
                                    }
                                   </div>
                        })
                        }
                        {App.IsUserInRole("DL") && this.state.currentItem.files?.filter(d => d.type == "OwnMap").length == 1 && 
                            <React.Fragment>
                                <Label>Uploadet materiale er godkendt som bæredygtighedsdokumentation</Label>
                                <div className={styles.toggleOptions}>
                                    <Label className={styles.toggleLabelLeft}>Nej</Label>
                                    <Toggle                                 
                                        disabled={this.state.mode == FormMode.ReadOnly}
                                        checked={this.state.currentItem.isOwnMapApproved}
                                        onChange={(ev, checked?: boolean) => { this.updateProperty({ isOwnMapApproved: checked }) } } 
                                    />
                                    <Label className={styles.toggleLabelRight}>Ja</Label>
                                </div>
                            </React.Fragment>
                        }
                    </div>
                    {this.state.showDocumentationOutdatedWarning && <div className={styles.documentationOutdated}><Icon iconName='WarningSolid' /><span>Dokumentationen er forældet!</span></div> }
                    
                    { this.state.mode != FormMode.New && this.state.currentItem.files?.length == 0 && <div>Der er <u>ikke</u> uploadet eget kort</div>
                    }                             
                    <TextField 
                        value={this.state.currentItem.estimatedAmount ? this.state.currentItem.estimatedAmount + "" : undefined}                         
                        onRenderLabel={() => { return <TooltipComponent name="Estimeret mængde" description="Programmet sammentæller selv de mængder der måtte være angivet som stakke på kortet. Hvis der ikke er angivet stakke eller der ikke er angivet mængde på alle stakkene så udfyld dette felt med estimeret mængde på opgaven." /> }}
                        description={this.getVolumeDescription()}
                        placeholder="Angiv estimeret mængde (mængden skal være et helt tal)" 
                        disabled={this.state.mode == FormMode.ReadOnly}
                        suffix="rm"
                        required={App.IsUserInRole("DL")}
                        onChange={((event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => { this.updateProperty({ estimatedAmount: parseFloat(newValue + "") }) } )}             
                    />
                    { this.state.extendedCreationRequired && 
                        <div>
                            <ComboBox
                                selectedKey={this.state.currentItem.trappingWorkPerformedByEntrepreneur?.toLocaleLowerCase()}
                                onRenderLabel={() => { return <TooltipComponent name="Fældearbejde udført af" description="Angiv hvem der har fældet træerne på opgaven. Der kan vælges en fra drop down listen ellers tilføj fritekst. Personen skal have gennemført Dalgas online SBP kursus. Se Flisportalens hjælpeside for yderlige information." /> }}
                                autoComplete="on"
                                allowFreeform={true}
                                disabled={this.state.mode == FormMode.ReadOnly}
                                options={this.state.SBP_Persons.map((value : ISBPPersonDTO) => { return { key: value.id?.toLocaleLowerCase()+"", text: value.name+"" } })}                                
                                onChange={this.onSBPPersonSelectionChange.bind(this, "trappingWorkPerformedByEntrepreneur") }
                            />
                            {this.state.currentItem.trappingWorkPerformedByEntrepreneur && this.state.SBP_Persons.find(x=> x.id == this.state.currentItem.trappingWorkPerformedByEntrepreneur)?.name?.endsWith("(indtastet manuelt)") &&
                                <p style={{color: 'red', marginBottom: 5}}>OBS. skal denne person ikke oprettes?</p>
                            }
                            <ComboBox
                                selectedKey={this.state.currentItem.extractionPerformedByEntrepreneur?.toLocaleLowerCase()}
                                onRenderLabel={() => { return <TooltipComponent name="Udkørsel udført af" description="Angiv hvem der har kørt træet ud (hvis relevant). Der kan vælges en fra drop down listen ellers tilføj fritekst. Personen skal have gennemført Dalgas online SBP kursus. Se Flisportalens hjælpeside for yderlige information." /> }}
                                autoComplete="on"
                                allowFreeform={true}
                                disabled={this.state.mode == FormMode.ReadOnly}
                                options={this.state.SBP_Persons.map((value : ISBPPersonDTO) => { return { key: value.id?.toLocaleLowerCase()+"", text: value.name+"" } })}                                
                                onChange={this.onSBPPersonSelectionChange.bind(this, "extractionPerformedByEntrepreneur") }
                            />
                            {this.state.currentItem.extractionPerformedByEntrepreneur && this.state.SBP_Persons.find(x=> x.id == this.state.currentItem.extractionPerformedByEntrepreneur)?.name?.endsWith("(indtastet manuelt)") &&
                                <p style={{color: 'red', marginBottom: 5}}>OBS. skal denne person ikke oprettes?</p>
                            }
                            <ComboBox
                                selectedKey={this.state.currentItem.woodchippingPerformedByEntrepreneur?.toLocaleLowerCase()}
                                onRenderLabel={() => { return <TooltipComponent name="Flishugning udført af" description="Angiv hvem der har flishugget træet. Ikke relevant hvis Dalgas står for flishugningen. Der kan vælges en fra drop down listen ellers tilføj fritekst. Personen skal have gennemført Dalgas online SBP kursus. Se Flisportalens hjælpeside for yderlige information." /> }}
                                autoComplete="on"
                                allowFreeform={true}
                                disabled={this.state.mode == FormMode.ReadOnly}
                                options={this.state.SBP_Persons.map((value : ISBPPersonDTO) => { return { key: value.id?.toLocaleLowerCase()+"", text: value.name+"" } })}                                
                                onChange={this.onSBPPersonSelectionChange.bind(this, "woodchippingPerformedByEntrepreneur") }
                            />
                            {this.state.currentItem.woodchippingPerformedByEntrepreneur && this.state.SBP_Persons.find(x=> x.id == this.state.currentItem.woodchippingPerformedByEntrepreneur)?.name?.endsWith("(indtastet manuelt)") &&
                                <p style={{color: 'red', marginBottom: 5}}>OBS. skal denne person ikke oprettes?</p>
                            }
                            <TooltipComponent name="Er der sket skade på værdifuld natur eller kulturminder?" description="Vælg ”Ja” hvis der er sket skade på værdifuld natur eller kulturminder under opgaveudførslen. Beskriv under ”Opgavebeskrivelse” hvordan skaden er blevet udbedret." />
                            <Label></Label>
                            <div className={styles.toggleOptions}>
                                <Label className={styles.toggleLabelLeft}>Nej</Label>
                                <Toggle                                 
                                    disabled={this.state.mode == FormMode.ReadOnly}
                                    checked={this.state.currentItem.hasNatureOrCultureBeenDamage}
                                    onChange={(ev, checked?: boolean) => { this.updateProperty({ hasNatureOrCultureBeenDamage: checked }) } } 
                                />
                                <Label className={styles.toggleLabelRight}>Ja</Label>
                            </div>
                            { this.state.currentItem.hasNatureOrCultureBeenDamage && 
                                <React.Fragment>
                                    <Label>Er skaden efterfølgede blevet udbedret?</Label>
                                    <div className={styles.toggleOptions}>
                                        <Label className={styles.toggleLabelLeft}>Nej</Label>
                                        <Toggle 
                                            disabled={this.state.mode == FormMode.ReadOnly}
                                            checked={this.state.currentItem.hasTheDamageSubsequentlyBeenRepaired}
                                            onChange={(ev, checked?: boolean) => { this.updateProperty({ hasTheDamageSubsequentlyBeenRepaired: checked }) } } 
                                        />
                                        <Label className={styles.toggleLabelRight}>Ja</Label>
                                    </div>
                                </React.Fragment>
                            }
                            <TooltipComponent name="Omfatter opgaven afdrift/rydning af ikkeskov eller ikke fredskovpligtig skov?" description="Hvis opgaven indeholder rydning af arealer der er omfattet af reglerne for genetablering skal der vælges ”Ja”. Der skal vedhæftes en ejererklæring samt angives en kort beskrivelse af hvad der er aftalt under ”Opgavebeskrivelse”. Se Flisportalens hjælpeside for yderlige information." />                            
                            <div className={styles.toggleOptions}>
                                <Label className={styles.toggleLabelLeft}>Nej</Label>
                                <Toggle 
                                    disabled={this.state.mode == FormMode.ReadOnly}                            
                                    checked={this.state.currentItem.continuedTreeGrowthOnTheAreaOrReplanting}
                                    onChange={(ev, checked?: boolean) => { this.updateProperty({ continuedTreeGrowthOnTheAreaOrReplanting: checked }) } } 
                                />
                                <Label className={styles.toggleLabelRight}>Ja</Label>
                            </div>
                            {this.state.currentItem.continuedTreeGrowthOnTheAreaOrReplanting &&
                                <React.Fragment>
                                    <div><i>Afdrevne arealer skal reetableres med træer. Der skal udfyldes og vedhæftes en ejererklæring</i></div>
                                    {(this.state.mode == FormMode.New || this.state.mode == FormMode.Edit) &&
                                        <React.Fragment>
                                            <PrimaryButton text="Upload ejererklæring" style={{marginTop:"10px"}} onClick={() => { this.fileInput_Declaration.click() }} />
                                            <input 
                                                style={{display:"none"}}
                                                type="file" 
                                                multiple={false}
                                                ref={fileInput => this.fileInput_Declaration = fileInput} 
                                                onChange={async (event) => { 
                                                    if(event.target.files) 
                                                    {
                                                        await this.addFiles(event.target.files, "Declaration");
                                                    } 
                                                }} 
                                            />
                                        </React.Fragment>
                                    }
                                    <div>                                    
                                    <TooltipComponent name="Ejererklæring" description="Ejererklæring skal udfyldes og uploades. Se Flisportalens hjælpeside for yderlige information samt en blank udgave af ejererklæringen. Andre dokumenter der indeholder de samme grundlæggende informationer og forpligtigelser kan også accepteres." />                            
                                        {this.state.currentItem.files?.filter(d => d.type == "Declaration")?.length == 0 && <div>Der er <u>ikke</u> uploadet ejererklæring</div>}
                                        {this.state.currentItem.files?.filter(d => d.type == "Declaration")?.map((v,i,a) =>{
                                            return <div key={`declarationFile_${i}`}>
                                            {v.isNew ? <Label className={styles.newLink}>{v.name}</Label> : <Link onClick={async() => { this.downloadFile(v.name); }}>{v.name}</Link>}
                                        </div>
                                        })}
                                    </div>
                                </React.Fragment>
                            }
                        </div>
                    }
                    { (this.state.currentItem.status == Status.RequiresAdditionalInfo || this.state.currentItem.status == Status.Rejected || App.IsUserInRole("DL") || App.IsUserInRole("Admin") || App.IsUserInRole("Finance")) && 
                        <TextField 
                            value={this.state.currentItem.comment} 
                            label="Kommentarer fra Dalgas" 
                            multiline={true}
                            rows={6}
                            disabled={!App.IsUserInRole("DL") || this.state.mode == FormMode.ReadOnly}
                            onChange={((event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => { this.updateProperty({ comment: newValue }) } )}             
                        />
                    }        
                    { (App.IsUserInRole("DL") || App.IsUserInRole("Admin") || App.IsUserInRole("Finance")) && 
                        <React.Fragment>                            
                            <Dropdown
                                required={App.IsUserInRole("DL")}
                                placeholder="Vælg fra listen"
                                disabled={this.state.mode == FormMode.ReadOnly}
                                label="Ressourcetype"
                                selectedKey={this.state.currentItem.ressourceType}
                                options={[
                                    { key: 'Flis jord', text: 'Flis jord' },
                                    { key: 'Grot', text: 'Grot' },
                                    { key: 'Fældeudkørt', text: 'Fældeudkørt' },
                                    { key: 'Skovlager', text: 'Skovlager' },
                                    { key: 'Rundtræ', text: 'Rundtræ' }
                                ]}
                                onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => { this.updateProperty({ressourceType: option?.key.toString() })}}
                            />                              
                        </React.Fragment>
                    }        
                </Stack>
                {this.state.showDelegatePanel && <DelegatePanel selectedAssignment={this.state.currentItem} onChanged={() => { this.props.onChanged(this.state.currentItem, false); }} onDismiss={() => { this.setState({showDelegatePanel: false}); } } />}
            </Panel>
        );
    }

    private async load()
    {   
        let certifications = [] as ICertificationDTO[];
        let supplierId = this.state.currentItem.supplierID ? this.state.currentItem.supplierID : App.SupplierId;

        if(supplierId)
        {
            certifications = await this.userService.GetCertificationsForSupplier(supplierId);
        }        

        if(this.props.allowSelectionOfSupplier)
        {
            let suppliers: ICreateOrUpdateSupplierDTO[] = await this.userService.GetSuppliers();
            this.setState({suppliers: suppliers, certifications: certifications});
        } else
        {
            this.setState({certifications: certifications});
        }

        // If existing item, calculate stack sum from MapData
        if(this.props.selectedAssignment && this.state.currentItem.mapData)
        {
            let mapData : IMapData = JSON.parse(this.state.currentItem.mapData) as IMapData;
            let treeSpecices = this.getTreeSpecicesUsedInStacks(mapData);

            this.setState({stackSumFromMap: mapData.stackSum, stackTreeSpecices: treeSpecices });
        }

        if(this.props.selectedAssignment)
        {
            let files = await this.assignmentService.GetFilesForAssignment(this.props.selectedAssignment);
            if(files.length > 0)
            {
                this.setState({currentItem: { ...this.state.currentItem, files: files }});  
            }            
        }

        this.validateForm();
        this.hasMapBeenChangedAfterDocumentationProcess();
    }

    private isMapValidForApproval() : boolean
    {
        let mapData : IMapData | undefined = undefined;
        
        if(this.state.currentItem.mapData == undefined)
        {
            return false;
        } else
        {
            mapData = JSON.parse(this.state.currentItem.mapData) as IMapData;
            return mapData.fixedTaskPosition != undefined && mapData.workingAreas.length > 0;
        }
    }

    private hasMapBeenChangedAfterDocumentationProcess()
    {
        let ci = this.state.currentItem;
        let result = false;

        // If both are undefined, not processed yet OR items before this new feature
        if(!ci.mapTimestamp && !ci.documentationTimestamp)
        {
            result = false;
        }

        // If maptimestamp is found and documentation timestamp is undefined, map has been changed since this new feature rolled out. Block!
        if(ci.mapTimestamp && !ci.documentationTimestamp)
        {
            result = true;
        }

        // If both maptimestamp and documentation timestamp are set, check if map has been changed after documentation. Block!
        if(ci.mapTimestamp && ci.documentationTimestamp)
        {
            result = ci.mapTimestamp.getTime() > ci.documentationTimestamp.getTime()
        }

        // If no files have been added, do not show warning yet
        if(!ci.files || ci.files.filter(d => d.type == "Documentation").length == 0)
        {
            result = false;
        }

        this.setState({showDocumentationOutdatedWarning: result});     
    }

    private validateForm_CanApprove()
    {
        let isValid = false;

        
        isValid = Utilities.CheckNotNullOrEmpty(this.state.currentItem.orderName) &&
            Utilities.CheckNotNullOrEmpty(this.state.currentItem.biomassCategory) &&
            Utilities.CheckNotNullOrEmpty(this.state.currentItem.description) &&
            Utilities.CheckNotNullOrEmpty(this.state.currentItem.ressourceType) &&
            Utilities.CheckNotNullOrEmpty(this.state.currentItem.hdaoNo?.toLocaleString()) &&
            Utilities.CheckNotNullOrEmpty(isNaN(this.state.currentItem.estimatedAmount as number) ? "" : this.state.currentItem.estimatedAmount + "") &&            
            this.state.currentItem.trappingDate != undefined && this.isMapValidForApproval();
            
        //Check documentation or own map
        if(isValid)
        {
            isValid = this.state.currentItem.isOwnMapApproved || this.state.currentItem.files?.filter(d => d.type == "Documentation").length == 3;
        }

        let mapData : IMapData | undefined = undefined;

        if(this.state.currentItem.mapData != undefined)
        {
            mapData = JSON.parse(this.state.currentItem.mapData) as IMapData;

            if(isValid)
            {
                isValid = mapData.stacks.some((x: IStackMarker, i, l) => l.filter(f => f.no == x.no).length > 1) === false;
            }
        } 

        if(!isValid)
        {
            let missingFields = [] as string[];

            if(!Utilities.CheckNotNullOrEmpty(this.state.currentItem.orderName))
            {
                missingFields.push("<li>Opgavenavn</li>");
            }

            if(!Utilities.CheckNotNullOrEmpty(this.state.currentItem.biomassCategory))
            {
                missingFields.push("<li>Flistype</li>");
            }

            if(!Utilities.CheckNotNullOrEmpty(this.state.currentItem.description))
            {
                missingFields.push("<li>Opgavebeskrivelse</li>");
            }

            if(this.state.currentItem.trappingDate == undefined)
            {
                missingFields.push("<li>Fældedato</li>");
            }

            if(!mapData)
            {
                missingFields.push("<li>Tegn kort</li>");
            }

            if(!mapData || mapData && mapData.fixedTaskPosition == undefined)
            {
                missingFields.push("<li>Opgave GPS-punkt på kortet</li>");
            }
            
            if(!mapData || mapData && mapData.workingAreas.length == 0)
            {
                missingFields.push("<li>Min. 1 arbejdsområde eller linje på kortet</li>");
            }

            if(mapData && mapData.stacks.length > 0)
            {
                const anyDuplicates = mapData.stacks.some((x: IStackMarker, i, l) => l.filter(f => f.no == x.no).length > 1);

                if(anyDuplicates)
                {
                    missingFields.push("<li>Alle stakke på kortet skal have tildelt et unikt nr.</li>");
                }
            }

            if(this.state.currentItem.isOwnMapApproved == null && this.state.currentItem.files?.filter(d => d.type == "Documentation").length != 3)
            {
                missingFields.push("<li>Dokumentation</li>");
            }

            if(this.state.currentItem.estimatedAmount == null || !Utilities.CheckNotNullOrEmpty(isNaN(this.state.currentItem.estimatedAmount as number) ? "" : this.state.currentItem.estimatedAmount + ""))
            {
                missingFields.push("<li>Estimeret mængde</li>");
            }

            if(!Utilities.CheckNotNullOrEmpty(this.state.currentItem.hdaoNo?.toLocaleString()))
            {
                missingFields.push("<li>Dalgas AO nr.</li>");
            }

            if(!Utilities.CheckNotNullOrEmpty(this.state.currentItem.projectNo))
            {
                missingFields.push("<li>Projektnummer</li>");
            }

            if(!Utilities.CheckNotNullOrEmpty(this.state.currentItem.ressourceType))
            {
                missingFields.push("<li>Ressourcetype</li>");
            }
            
            let validationMessage = `<div>Du skal udfylde alle obligatoriske felter for at fortsætte. Du mangler: </div><ul>${missingFields.join("")}</ul>`;

            this.setState({showValidationBox: true, validationMessage: validationMessage});
        }

        return isValid;
    }

    public GroupSuppliers()
    {
        let options = [] as IComboBoxOption[];
        
        for(let item of this.state.suppliers.sort(this.SortOnRegion))
        {
            if(options.filter(d => d.key == item.region?.name).length == 0)
            {
                options.push(
                    {
                        key: item.region?.name,
                        text: item.region?.name,
                        itemType: SelectableOptionMenuItemType.Header
                    } as IComboBoxOption
                );

                for(let child of this.state.suppliers.filter(d => d.region?.name == item.region?.name))
                {
                    options.push(
                        {
                            key: child.id,
                            text: child.name,
                        } as IComboBoxOption
                    );
                }
            }
        }

        return options;
    }

    private getVolumeDescription() : string
    {
        let text = `Kortet summerer til: ${this.state.stackSumFromMap} rm`;

        if(this.state.stackTreeSpecices != "")
        {
            text = `${text} (${this.state.stackTreeSpecices})`;
        }

        return text;
    }

    private getTreeSpecicesUsedInStacks(mapData: IMapData) : string
    {
        let all = mapData.stacks.filter(d => d.treeSpecices).map((v, i, a) => { return v.treeSpecices; });
        let treeSpecices = all.filter((item, index) => all.indexOf(item) === index);

        return treeSpecices.join(", ").toLowerCase();
    }

    private SortOnRegion(a: ICreateOrUpdateSupplierDTO, b: ICreateOrUpdateSupplierDTO) 
    {
        let aRegion = a.region ? a.region?.name : "";
        let bRegion = b.region ? b.region?.name : "";

        if (aRegion > bRegion) return 1;
        if (bRegion > aRegion) return -1;
        
        return 0;
    }

    private EnsureFreeFormInputsInArray(SBP_Persons : ISBPPersonDTO[], field: string)
    {
        let currentAssignmentTemp = (this.state.currentItem as any);

        if(currentAssignmentTemp[field] && !SBP_Persons.find(d => d.id?.toLocaleLowerCase() == currentAssignmentTemp[field]?.toLocaleLowerCase()))
        {
            SBP_Persons.push({id: currentAssignmentTemp[field], name: `${currentAssignmentTemp[field]} (indtastet manuelt)`});
        }
    }

    private async onSupplierChange(items: ITag[] | undefined)
    {
        if(items && items.length > 0)
        {
            let supplierId = items[0].key.toString();
            let projectNo = this.state.suppliers.filter(d => d.id == supplierId)[0].projectNo;

            this.updateProperty({supplierID: supplierId, projectNo: projectNo});

            let certifications = await this.userService.GetCertificationsForSupplier(supplierId);

            this.setState({
                certifications: certifications
            });
        }

    }

    private onSBPPersonSelectionChange(field: string, event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string): void
    {
        let tempObject = {} as any;
        
        if(option)
        {
            tempObject[field] = option.key.toString();
        } else
        {
            this.setState({
                SBP_Persons: [...this.state.SBP_Persons, {id: value?.toLocaleLowerCase() + "", name: value + ""}]
            });

            tempObject[field] = value;         
        }

        this.updateProperty(tempObject);
    }

    private async updateProperty(entity: Partial<IAssignmentDTO>): Promise<void> {
        this.setState({ currentItem: { ...this.state.currentItem, ...entity } }, () => { this.validateForm(); });
    }

    private async validateForm()
    {
        let extendedCreation = false;

        // Hvis leverandør ikke er certificeret eller hvis "NEJ" er valgt (eks. på vegne af en leverandør eller en certificeret leverandør)
        if((this.state.certifications?.length == 1 && this.state.currentItem.certification == undefined) || this.state.currentItem.certification && this.state.currentItem.certification.name == "Træet er forberedt til Dalgas SBP certificering")
        {
            extendedCreation = this.state.currentItem.biomassCategory?.startsWith("Skov:") || this.state.currentItem.biomassCategory?.startsWith("Ikke skov:") as boolean;
        }
        
        if(extendedCreation)
        {
            if(this.state.SBP_Persons.length == 0)
            {
                let SBP_Persons = [] as ISBPPersonDTO[];

                if(this.props.allowSelectionOfSupplier || App.IsUserInRole("DL") || App.IsUserInRole("Admin"))
                {
                    if(this.state.currentItem.supplierID)
                    {
                        SBP_Persons = await this.userService.GetSBPPersons(this.state.currentItem.supplierID + "");
                    }                    
                } else
                {
                    SBP_Persons = await this.userService.GetSBPPersonsForCurrentSupplier();
                }
                
                SBP_Persons = SBP_Persons.filter(x => x.educated != null && x.educated == true);

                this.EnsureFreeFormInputsInArray(SBP_Persons, "responsible");
                this.EnsureFreeFormInputsInArray(SBP_Persons, "trappingWorkPerformedByEntrepreneur");
                this.EnsureFreeFormInputsInArray(SBP_Persons, "extractionPerformedByEntrepreneur");
                this.EnsureFreeFormInputsInArray(SBP_Persons, "woodchippingPerformedByEntrepreneur");

                
                this.setState({ SBP_Persons:SBP_Persons });
            }
        }

        this.setState(
            {
                extendedCreationRequired: extendedCreation
            }
        );
    }

    private async deleteAssignment()
    {
        if(window.confirm("Er du sikker på, at du ønsker at slette denne opgave?"))
        {           
            let result : boolean = false;
            if(this.props.selectedAssignment)
            {
                result = await this.assignmentService.DeleteAssignment({ ID: this.state.currentItem.id + "" });
            } 

            if(result)
            {
                this.props.onChanged(this.state.currentItem, false);
            } else
            {
                alert("Der er sket en fejl under sletning af opgaven. Prøv venligst igen senere.");
            } 
        }
    }

    private async onAddOrUpdateAssignment(currentItem: IAssignmentDTO)
    {
        this.setState({isSaving: true}, async () => {
            let result : boolean = false;
            if(this.props.selectedAssignment || currentItem.id != undefined)
            {
                result = await this.assignmentService.UpdateAssignment(currentItem);
            } else
            {
                let newId = await this.assignmentService.CreateAssignment(currentItem);
                result = newId != "";
            }
    
            if(result)
            {
                this.props.onChanged(currentItem, false);
            } else
            {
                let errorMessage = this.props.selectedAssignment ? "Der er sket en fejl under opdateringen af opgaven. Prøv venligst igen senere." : "Der er sket en fejl under oprettelse af opgaven. Prøv venligst igen senere.";
                alert(errorMessage);
            }
    
            this.setState({isSaving: false});
        });        
    }

    private async takeNextPINFONo()
    {
        let result  = await this.assignmentService.TakeNextPINFONo(this.state.currentItem);

        this.setState({currentItem : {...this.state.currentItem, hdaoNo: result }});
    }

    private async downloadFile(fileName: string)
    {
        let href = await this.assignmentService.DownloadFile(this.state.currentItem, fileName); 

        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    private async preSaveAssignment()
    {
        let newId = await this.assignmentService.CreateAssignment(this.state.currentItem);

        this.updateProperty({id: newId})
    }

    private async deleteFile(file: IAssignmentFileDTO)
    {
        await this.assignmentService.DeleteFile(this.state.currentItem, file);

        let files = this.state.currentItem.files?.filter(d => d !== file);

        this.updateProperty({files: files})
    }

    private async addFiles(eventFiles: FileList, type: string)
    {
        let files = this.state.currentItem.files;

        let promises = Array.from(eventFiles).map(file => {

            // Define a new file reader
            let reader = new FileReader();
            
            // Create a new promise
            return new Promise(resolve => {
                
                reader.readAsDataURL(file); 

                // Resolve the promise after reading file
                reader.onload = () => { 
                    files?.push({name: file.name, content: reader.result + "", type: type, isNew: true});          
                    resolve(reader.result); 
                }
            });            
        });

        await Promise.all(promises);

        this.updateProperty({files: files});
    }

    private trimOrderName(newValue: string) : string
    {
        return newValue.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
    }
}